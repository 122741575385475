<template>
  <div class="header" id="questionsHeader">
    <div class="header-body">
      <div class="row align-items-center" style="min-height: 41px">
        <!-- title -->
        <div class="col">
          <h1 class="header-title">Custom Questions</h1>
        </div>

        <!-- buttons -->
        <div class="col-auto">
          <button
            class="btn btn-primary"
            v-if="$route.name === 'custom-questions-questions'"
            @click="openQuestionCreationModal"
          >
            <span class="fas fa-plus"></span>
            Create Question
          </button>
        </div>
      </div>
    </div>

    <!-- nav -->
    <div class="header-body pt-0">
      <div class="row align-items-center justify-content-center">
        <div class="">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
              <router-link
                :to="{ name: navItem.route }"
                class="nav-link py-3"
                :class="{ active: navItem.route === activeTab }"
              >
                {{ navItem.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CustomQuestionsHeader',
  components: {
    SlideFadeTransition,
  },
  data: () => ({
    activeTab: 'reports',
  }),
  computed: {
    ...CompanyModule.mapGetters(['selectIsPermitted']),
    navItems() {
      return [
        {
          title: 'Reports',
          route: 'custom-questions-reports',
        },
        ...(this.selectIsPermitted('cqQuestionsReadAccess')
          ? [
              {
                title: 'Questions',
                route: 'custom-questions-questions',
              },
            ]
          : []),
        ...(this.selectIsPermitted('cqSettingsReadAccess')
          ? [
              {
                title: 'Settings',
                route: 'custom-questions-settings',
              },
            ]
          : []),
      ]
    },
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(name) {
        this.activeTab = name
      },
    },
  },
  methods: {
    openQuestionCreationModal() {
      this.$root.$emit('bv::show::modal', 'questionCreationModal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
