<template>
  <SlideFadeTransition v-if="initialized">
    <router-view />
  </SlideFadeTransition>
</template>

<script>
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const SurveyModule = createNamespacedHelpers('survey')
const CustomQuestionModule = createNamespacedHelpers('customQuestion')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'CustomQuestionViews',
  components: {
    SlideFadeTransition,
  },
  data: () => ({
    initialized: false
  }),
  async mounted() {
    this.setQueryPending(true)
    await Promise.all([
      this.getUserLocationsByActiveCompany(),
      this.getSources(),
      this.fetchCategories(),
      this.getCustomQuestions(),
      this.fetchGroups({
        memberType: 'location',
        companyIds: this.selectActiveCompanyIds,
      }),
    ])
    this.setQueryPending(false)
    this.initialized = true
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...SurveyModule.mapActions(['getSources']),
    ...CustomQuestionModule.mapActions(['fetchCategories', 'getReports', 'getCustomQuestions']),
    ...CustomQuestionModule.mapMutations(['setQueryPending']),
    ...GroupModule.mapActions(['fetchGroups']),
  },
}
</script>
