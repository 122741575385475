<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <Header />
      <CallToTextViews v-if="activeCompany.settings.ivrEnabled" />
      <OffState v-else />
    </div>

    <b-spinner style="height: 99vh" v-else />
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import MainContent from '@/components/MainContent/MainContent'
import CallToTextViews from '@/components/Modules/CallToText/Views/CallToTextViews'
import Header from '@/components/Modules/CallToText/Components/CallToTextHeader'
import OffState from '@/components/Modules/CallToText/Views/CallToTextOffState'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CallsPage',
  components: {
    MainContent,
    CallToTextViews,
    Header,
    OffState,
  },
  mounted() {
    trackEvent(this.$intercom, 'ReportBuilder')
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
  },
}
</script>
