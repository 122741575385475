<template>
  <div>
    <SlideFadeTransition v-if="initialized">
      <router-view />
    </SlideFadeTransition>
  </div>
</template>

<script>
import SlideFadeTransition from '@/components/Transitions/SlideFadeTransition'
import { createNamespacedHelpers } from 'vuex'

const LocationModule = createNamespacedHelpers('location')
const GroupModule = createNamespacedHelpers('group')
const DataCoreModule = createNamespacedHelpers('dataCore')

export default {
  name: 'CallToTextViews',
  components: {
    SlideFadeTransition,
  },
  data: () => ({
    initialized: false,
  }),
  async mounted() {
    await this.getUserLocationsByActiveCompany()
    await this.fetchGroups({
      memberType: 'location',
      companyIds: this.selectActiveCompanyIds,
    })
    this.initialized = true
  },
  computed: {
    ...DataCoreModule.mapGetters(['selectActiveCompanyIds']),
  },
  methods: {
    ...LocationModule.mapActions(['getUserLocationsByActiveCompany']),
    ...GroupModule.mapActions(['fetchGroups']),
  },
}
</script>
