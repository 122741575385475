<template>
  <div class="card col-12 mt-6">
    <div class="card-body text-center">
      <div class="row">
        <div class="col-12">
          <img
            src="/img/illustrations/rocket.png"
            alt="..."
            class="img-fluid mt--5 mb-4"
            style="max-width: 150px"
          />

          <h2>Catch every call. No more missed guests or interrupted staff.</h2>

          <p class="text-muted mb-1">
            A customizable phone tree that immediately texts callers with requested info, while
            collecting feedback and seamlessly integrating with Ovation.
          </p>

          <div class="text-center mb-4">
            <a
              href="https://ovationup.com/call-to-text/"
              target="_blank"
              style="text-decoration: underline"
            >
              Learn more
            </a>
          </div>

          <a
            href="mailto:upgrade@ovationup.com?subject=Upgrade%20Request&body=I%20would%20like%20access%20to%20Call-to-Text."
            class="btn btn-lg btn-primary"
          >
            Request Access
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CallToTextOffState',
}
</script>
