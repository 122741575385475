import { render, staticRenderFns } from "./CustomQuestionsHeader.vue?vue&type=template&id=d1300df4&scoped=true"
import script from "./CustomQuestionsHeader.vue?vue&type=script&lang=js"
export * from "./CustomQuestionsHeader.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1300df4",
  null
  
)

export default component.exports