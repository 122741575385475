<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <Header />
      <GiftCardViews />
    </div>

    <b-spinner style="height: 99vh" v-else />
  </MainContent>
</template>

<script>
import { trackEvent } from '@/lib/analytics'
import MainContent from '@/components/MainContent/MainContent'
import GiftCardViews from '@/components/Modules/GiftCard/Views/GiftCardViews'
import Header from '@/components/Modules/GiftCard/Components/GiftCardHeader'
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'GiftCardPage',
  components: {
    MainContent,
    GiftCardViews,
    Header,
  },
  mounted() {
    trackEvent(this.$intercom, 'GiftCardPage')
  },
  computed: {
    ...CompanyModule.mapState(['activeCompany']),
  },
}
</script>
