<template>
  <MainContent>
    <div class="container-fluid" :key="activeCompany._id" v-if="activeCompany">
      <div v-if="activeCompany.customQuestionsEnabled">
        <Header />
        <CustomQuestionViews />
      </div>
      <OffState v-else></OffState>
    </div>
  </MainContent>
</template>

<script>
import MainContent from '@/components/MainContent/MainContent'
import Header from '@/components/Modules/CustomQuestions/CustomQuestionsHeader'
import CustomQuestionViews from '@/components/Modules/CustomQuestions/CustomQuestionViews'
import CustomQuestionFeatureOffState from '@/components/Modules/CustomQuestions/OffStates/CustomQuestionFeatureOffState'
import { trackEvent } from '@/lib/analytics'
import { createNamespacedHelpers } from 'vuex'

const companyModule = createNamespacedHelpers('company')

export default {
  name: 'CustomQuestionsPage',
  components: {
    MainContent,
    CustomQuestionViews,
    OffState: CustomQuestionFeatureOffState,
    Header,
  },
  mounted() {
    trackEvent(this.$intercom, 'CustomQuestions')
  },
  computed: {
    ...companyModule.mapState(['activeCompany']),
  },
  methods: {},
}
</script>

<style scoped lang="scss"></style>
