<template>
  <div class="header" id="questionsHeader">
    <div class="header-body">
      <div class="row align-items-center" style="min-height: 41px">
        <!-- title -->
        <div class="col">
          <h1 class="header-title">Call-to-Text</h1>
        </div>
      </div>
    </div>

    <!-- nav -->

    <!-- uncomment this when other nav options are built out -->
    <!-- <div class="header-body pt-0">
      <div class="row align-items-center justify-content-center">
        <div class="">
          <ul class="nav nav-tabs nav-overflow header-tabs">
            <li class="nav-item" v-for="(navItem, idx) in navItems" :key="idx">
              <router-link
                :to="{ name: navItem.route }"
                class="nav-link py-3"
                :class="{ active: navItem.route === activeTab }"
              >
                {{ navItem.title }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'CallToTextHeader',
  data: () => ({
    activeTab: 'reports',
  }),
  computed: {
    navItems() {
      return [
        {
          title: 'Reports',
          route: 'call-reports',
        },
        // uncomment this to add the settings nav option
        // {
        //   title: 'Settings',
        //   route: 'call-settings',
        // },
      ]
    },
  },
  watch: {
    '$route.name': {
      immediate: true,
      handler(name) {
        this.activeTab = name
      },
    },
  },
}
</script>
